// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// intro-section
// =========================================================================
.intro-section {
	position: relative;
	padding: 30px 0 0 0 !important;

	&::after {
		content: url('/images/visual-round-bottom-green.svg');
		display: block;
		margin: 6vh auto 0 auto;
		font-size: 0;
		line-height: 0;
		background: $white;
	}

	.container-two-columns {
		.container-holder {
			align-items: center;
		}
	}

	// global-usps
	div.global-usps {
		margin-bottom: 6vh;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			li {
				display: flex;
				align-items: center;
				margin: 2.5px 10px;
				color: $white;
				font-size: 16px;
				font-weight: 700;
				text-align: center;

				@include media-breakpoint-up(xl) {
					margin: 0;
					flex-grow: 1;
				}

				i,
				img {
					margin-right: 10px;
					color: $yellow;
					font-size: 18px;
				}

				a {
					text-decoration: none !important;
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 8vh 0;

	div.link {
		margin-top: 40px;
		text-align: center;

		a.link {
			@extend .btn;
			@extend .btn-secondary;
		}
	}

	// collection
	.collection {
		.card {
			@extend .default-card;

			.card-body {
				@include media-breakpoint-up(xl) {
					padding: 30px;
				}

				.card-caption {
					.card-title {
						@include font-size($h1-font-size);
						color: $green-dark;
						line-height: 1;
					}

					.card-description {
						ul {
							margin: 15px 0 0 0;
							padding: 0;
							list-style: none;

							li {
								position: relative;
								padding-left: 50px;
								font-size: 14px;
								line-height: 1.2;

								strong {
									font-size: 20px;
								}

								&:not(:last-of-type) {
									margin-bottom: 15px;
									padding-bottom: 15px;
									border-bottom: 1px solid $green-dark;
								}

								&::before {
									content: '\f0a9';
									position: absolute;
									top: 0;
									left: 0;
									font-family: $font-awesome;
									color: $green-dark;
									font-size: 36px;
									font-weight: 900;
								}

								a {
									color: $black;
									text-decoration: none;

									strong {
										color: $green-dark;
										transition: 0.5s;
									}

									&:hover {
										color: $black;

										strong {
											color: $red;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// experience-section
// =========================================================================
.experience-section {
	padding: 8vh 0 4vh 0;
	background-color: $green-light;

	// collection
	.collection {
		margin-top: 15px;

		&.grid {
			.grid-items {
				@include media-breakpoint-down(sm) {
					align-items: flex-start;
					justify-content: flex-start;
					margin-top: 75px;
				}

				.item {
					@include make-col(6);

					@include media-breakpoint-down(sm) {

						&:nth-child(odd) {
							.card {
								margin-top: -75px;
							}
						}
					}

					@include media-breakpoint-up(md) {
						@include make-col(4);
					}

					@include media-breakpoint-up(lg) {
						@include make-col(3);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(3);
					}
				}
			}
		}

		.card {
			background: none;
			border: none;
			border-radius: 0;

			.card-image {
				position: relative;
				overflow: visible;

				picture {
					display: block;
					border-radius: 100%;
					border: 5px solid $white;
					overflow: hidden;

					@include media-breakpoint-up(lg) {
						border-width: 8px;
					}
				}

				&::after {
					content: "\f0a9";
					position: absolute;
					right: 12px;
					bottom: 12px;
					border: 3px solid $white;
					border-radius: 100%;
					color: $green-dark;
					font-size: 24px;
					font-weight: 900;
					line-height: 1;
					font-family: $font-awesome;
					transition: 0.5s;

					@include media-breakpoint-up(lg) {
						border-width: 6px;
						font-size: 36px;
					}
				}

				&:hover {
					&::after {
						color: $red;
					}
				}
			}

			.card-body {
				margin-top: 5px;
				padding: 0;

				.card-caption {
					text-align: center;

					.card-title {
						margin-bottom: 0;
						color: $green-dark;
						font-size: 16px;

						@include media-breakpoint-up(sm) {
							font-size: 20px;
						}

						@include media-breakpoint-up(lg) {
							font-size: 24px;
						}
					}

					.card-subtitle,
					.card-description {
						display: none;
					}
				}
			}
		}
	}
}

// news-section
// =========================================================================
.news-section {
	padding: 6vh 0;
	background: $green-light;

	// collection
	.collection {
		&.grid {
			.grid-items {
				margin-bottom: 0;

				.item {
					@include make-col(12);
					margin-bottom: 20px;

					.card {
						padding-bottom: 20px;
						background: none;
						border-radius: 0;
						border: none;
						border-bottom: 1px solid $green-dark;

						.card-image {
							display: none;
						}

						.card-body {
							padding: 0;

							.card-caption {
								display: flex;
								flex-direction: column;

								.card-title-link {
									margin: 0;

									.card-title {
										display: inline-flex;
										align-items: center;
										margin: 0;
										color: $green-dark;
										font-size: 20px;

										&::after {
											content: '\f0a9';
											margin-left: 5px;
											font-size: 16px;
											line-height: 1;
											font-family: $font-awesome;
										}
									}

									&:hover {
										.card-title {
											color: $red;
										}
									}
								}

								.card-subtitle {
									order: -1;
									margin: 0;
									font-size: 14px;
									font-weight: 400;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;

	&::before {
		content: url('/images/visual-round-top-green-lighter.svg');
		position: absolute;
		left: 0;
		right: 0;
		bottom: 50%;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 50%;
		background: $green-light;
	}

	@include media-breakpoint-down(lg) {
		.container {
			max-width: unset;
			padding-left: 0;
			padding-right: 0;

			.container-holder {
				margin-left: 0;
				margin-right: 0;

				.column {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}

	// image-collection
	.image-collection {
		&.owl-carousel {
			@include media-breakpoint-up(xl) {
				border-radius: 15px;
				box-shadow: 6px 6px 12px rgba($black, 0.15);
				overflow: hidden;
			}

			.item {
				@include media-breakpoint-down(lg) {
					flex-direction: column;
				}

				@include media-breakpoint-up(xl) {
					min-height: 540px;
					align-items: unset;
				}

				picture {
					@include media-breakpoint-down(lg) {
						height: 400px;
					}

					@include media-breakpoint-up(xl) {
						@include make-col(8);
					}
				}

				.owl-caption-holder {
					position: static;
					background: $green-dark;

					@include media-breakpoint-down(lg) {
						position: relative;
						width: auto;
						border-radius: 15px;
						margin: -50px 30px 0 30px;
					}

					@include media-breakpoint-down(sm) {
						margin-left: 15px;
						margin-right: 15px;
					}

					@include media-breakpoint-up(xl) {
						@include make-col(4);
					}

					.owl-container {
						.owl-caption {
							text-align: left;

							@include media-breakpoint-down(lg) {
								padding: 65px 30px 45px 30px;
							}

							@include media-breakpoint-up(xl) {
								padding: 45px 15px;
							}

							.owl-title {
								@include font-size($h3-font-size);
							}

							.owl-subtitle {
								@include font-size($h4-font-size);
							}
						}

						.owl-btn {
							@extend .btn-secondary;
						}
					}
				}
			}

			// owl-nav
			.owl-nav {
				display: none;
			}

			// owl-dots
			.owl-dots {
				@include media-breakpoint-down(lg) {
					top: 370px;
					bottom: auto;
				}

				.owl-dot {
					span {
						width: 18px;
						height: 18px;
						box-shadow: none;
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
