// footer
.footer {
	margin-top: calc(8vh + 60px);

	// footer-outro
	.footer-outro {
		position: relative;

		&::after {
			content: url('/images/visual-round-top-white.svg');
			z-index: 100;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			font-size: 0;
			line-height: 0;
		}

		img {
			margin-top: calc(-6vh - 60px);
		}

		.container-two-columns {
			.container-holder {
				.column {
					&.two {
						@include media-breakpoint-down(sm) {
							order: -1;
						}
					}
				}
			}
		}
	}

	// footer-address
	.footer-address {
		margin: 6vh 0;
		font-size: 16px;
		text-align: center;

		a {
			font-size: 18px;
			font-weight: 700;
		}
	}

	// footer-social
	.footer-social {
		margin: 6vh 0;

		.footer-logolink {
			ul {
				align-items: center;
				justify-content: center;

				li {
					margin: 7.5px;

					a {
						display: block;
						width: 54px;
						height: 54px;
						background-color: $green-dark;
						border-radius: 100%;
						color: $white;
						font-size: 20px;
						line-height: 54px;
						text-align: center;

						&:hover {
							background-color: $red;
							color: $white;
						}
					}
				}
			}
		}
	}

	// footer-partners
	.footer-partners {
		margin: 6vh 0;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 -15px;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;

				img {
					border-radius: 0;
				}
			}
		}

	}

	// footer-copyright
	.footer-copyright {
		padding-bottom: 30px;

		// footer-text
		.footer-text {
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				li {
					font-size: 14px;

					@include media-breakpoint-up(sm) {
						+li {
							&::before {
								content: '-';
								margin: 0 10px;
							}
						}
					}

					a {
						color: $green-dark;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

&.home {
	.footer {
		margin-top: 0;
		border-top: 60px solid $green-light;
	}
}
