& {
	@include media-breakpoint-up(xl) {
		font-size: 18px;
	}
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-large {
		max-width: 960px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}

	&.container-two-columns {
		.container-holder {
			.column {
				&.column-narrow {
					@include media-breakpoint-up(lg) {
						@include make-col(5);
					}
				}

				&.column-wide {
					@include media-breakpoint-up(lg) {
						@include make-col(7);
					}
				}
			}
		}
	}
}

// headings
h1,
h2 {
	margin-bottom: 1rem;
}

// paragraph
p {
	min-height: 1rem;
	margin-bottom: 1.5rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	background: $green-light;
	border-radius: 15px;
	box-shadow: 6px 6px 12px rgba($black, 0.15);

	.card-body {
		.card-caption {
			.card-title {
				font-size: $h4-font-size;
			}

			.card-subtitle {
				font-size: $h5-font-size;
			}
		}
	}
}

// image-pair
.image-pair {
	position: relative;
	margin-bottom: 135px !important;

	.img-large {
		max-width: 380px;
	}

	.img-small {
		position: absolute;
		right: 0;
		bottom: 0;
		max-width: 240px;
		margin-bottom: -120px;
	}
}

// img-round
.img-round {
	img {
		border-radius: 100%;
		border: 8px solid $white;
	}
}

// image-collection
.image-collection {
	&.owl-carousel {
		.item {
			picture {
				width: 100%;
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
				}
			}

			.owl-caption-holder {
				z-index: 100;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

// link-arrow
.link-arrow {
	display: inline-flex;
	align-items: center;
	font-size: 18px;
	font-weight: 700;
	text-decoration: none;

	@include media-breakpoint-up(xl) {
		font-size: 20px;
	}

	&::after {
		content: '\f0a9';
		margin-left: 5px;
		font-size: 16px;
		line-height: 1;
		font-family: $font-awesome;
	}

	&:hover {
		color: $red;
	}
}
