// mini-sab
.mini-sab {
	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 0;
			padding: 15px 0;
			border-radius: 30px;
			background: $green-light;
			box-shadow: 6px 6px 12px rgba($black, 0.15);
			color: #fff;

			// below eyecatcher
			@include media-breakpoint-down(md) {
				margin-top: -50px;
			}

			// on eyecatcher
			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
				padding-left: 30px;
				padding-right: 30px;
				border-radius: 60px;
			}

			@include media-breakpoint-up(xl) {
				padding-left: 45px;
				padding-right: 45px;
			}

			// heading
			.heading {
				margin-bottom: 3px;
				color: $green-dark;
				font-size: 18px;
				font-weight: 700;

				@include media-breakpoint-down(md) {
					margin-bottom: 10px;
					font-size: 16px;
					text-align: center;

				}
			}
		}
	}
}

&.home {
	.mini-sab {
		@include media-breakpoint-down(md) {
			background: $green-dark;
		}

		.container {
			@include media-breakpoint-up(lg) {
				bottom: 100px;
			}
		}
	}
}
