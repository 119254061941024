// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $green-light;
	box-shadow: $shadow;
	transition: 0.5s;

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	.header-top {
		@include media-breakpoint-down(lg) {
			padding-right: 40px;
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 40px;
			height: 40px;
			padding: 0;
			border: none;
			background: $green-dark;
			border-radius: 100%;
			color: $yellow;
			font-size: 20px;
			transition: color 0.5s;

			i {
				line-height: 40px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 150px;
		margin: 10px auto;
		transition: width 0.5s;

		@include media-breakpoint-up(xl) {
			width: 245px;
			margin: 45px auto 0 auto;
		}

		a {
			display: block;

			img {
				@extend .w-100;
			}
		}
	}

	&.sticky {
		.logo {
			position: fixed;
			top: 10px;
			left: 15px;
			width: 150px;
			margin: 0;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin: 15px 30px 30px 30px;

				>.nav-item {
					&:not(:last-of-type) {
						border-bottom: 1px solid $green-dark;
					}

					>.nav-link {
						display: flex;
						align-items: center;
						padding-top: 0.75rem;
						padding-bottom: 0.75rem;
						color: $green-dark;
						font-size: 18px;
						font-weight: 700;

						&:hover {
							color: $green;
						}
					}

					&.active {
						>.nav-link {
							color: $green;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0;
						padding: 0 0 0.75rem 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 18px;

							.nav-link {
								padding: 0.15rem 0;
								color: $green-dark;

								&::before {
									content: "\f0a9";
									margin-right: 10px;
									font-size: 16px;
									font-weight: 900;
									line-height: 1;
									font-family: $font-awesome;
								}

								&:hover {
									color: $green;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin: 20px auto;

				>.nav-item {
					margin: 0 10px;
					font-size: 18px;
					font-weight: 700;

					>.nav-link {
						padding: 0.15rem 0 !important;
						border-bottom: 2px solid transparent;
						color: $green-dark;

						&:hover {
							border-color: $green;
							color: $green;
						}
					}

					&.active {
						>.nav-link {
							border-color: $green;
							color: $green;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						margin-top: 20px;
						padding: 1rem;
						border-radius: 8px;
						border: none;
						box-shadow: 6px 6px 12px rgba($black, 0.15);

						&::before {
							left: 50px;
							right: auto;
							border-left-width: 10px;
							border-right-width: 10px;
							border-bottom: 20px solid $white;
						}

						&::after {
							display: none;
						}

						.nav-item {
							font-size: 18px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $green-dark;

								&::before {
									content: "\f0a9";
									margin-right: 10px;
									font-size: 16px;
									font-weight: 900;
									line-height: 1;
									font-family: $font-awesome;
								}

								&:hover {
									color: $green;
								}
							}

							&.active {
								.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-badge
	div.book-badge {
		ul {
			li {
				a {
					position: fixed;
					top: -75px;
					right: -75px;
					width: 150px;
					aspect-ratio: 1;
					transform: rotate(45deg);
					display: flex;
					align-items: flex-end;
					justify-content: center;
					padding-bottom: 5px;
					background-color: $red;
					box-shadow: inset 6px -6px 6px rgba($black, 0.15);
					color: $white;
					font-size: 14px;
					font-weight: 700;
					text-align: center;
					text-transform: uppercase;
					text-decoration: none;
					transition: background-color 0.5s, color 0.5s;
					animation: bookBadge 0.5s 1s both;

					@keyframes bookBadge {
						0% {
							top: -150px;
							right: -150px;
						}

						100% {
							top: -75px;
							right: -75px;
						}
					}

					&::after {
						content: "\f0a9";
						position: absolute;
						bottom: 30px;
						font-size: 18px;
						font-weight: 900;
						line-height: 1;
						font-family: $font-awesome;
						transform: rotate(-45deg);
					}

					&:hover {
						background-color: $yellow;
						color: $black;
					}

					@include media-breakpoint-up(xl) {
						top: -115px;
						right: -115px;
						width: 230px;
						font-size: 20px;

						@keyframes bookBadge {
							0% {
								top: -230px;
								right: -230px;
							}

							100% {
								top: -115px;
								right: -115px;
							}
						}

						&::after {
							bottom: 50px;
							font-size: 30px;
						}
					}
				}
			}
		}
	}
}
