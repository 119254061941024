// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}

	@include media-breakpoint-up(xl) {
		min-height: 600px;
		height: calc(100vh - 215px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	&::after {
		content: url('/images/visual-round-top-white.svg');
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
	}

	.owl-carousel {
		.item {
			.owl-caption-holder {
				.owl-container {
					max-width: 720px;

					.owl-caption {
						.owl-title {
							margin-bottom: 0;
							@include font-size($h1-font-size);

							@include media-breakpoint-up(xl) {
								font-size: 48px;
							}
						}
					}
				}
			}
		}
	}

	// owl-dots
	.owl-nav,
	.owl-dots {
		display: none;
	}
}

&.home {
	.eyecatcher {
		&::after {
			content: url('/images/visual-round-top-green.svg');
		}
	}
}
