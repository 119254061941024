// btn
.btn {
	font-weight: 700;

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: $red;
			border-color: $red;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		display: inline-flex;
		align-items: center;
		padding: 11px 11px 11px 30px;
		border-radius: 30px;

		&::after {
			content: '\f0a9';
			margin-left: 10px;
			font-size: 36px;
			line-height: 1;
			font-family: $font-awesome;
		}

		&:hover {
			background-color: $red;
			border-color: $red;
			color: $white;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
